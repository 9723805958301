import React from 'react';

import styles from './ProductsWrapper.module.scss';

interface Props {
  category: string;
  link: string;
  children: Array<React.ReactNode>;
}

const ProductsWrapper: React.FC<Props> = (props: Props) => (
  <>
    <a href={props.link}>
      <h4 className={styles.title}>{props.category}</h4>
    </a>
    <div className={styles.wrapper}>{props.children}</div>
  </>
);

ProductsWrapper.displayName = 'ProductsWrapper';

export default ProductsWrapper;
