import React, { useState } from 'react';

import Layout from '../Layout';
import Image from '../Image';
import cx from 'classnames';

import styles from './Banner.module.scss';
import { Lazy } from 'src/components-v2/Helpers';

const Banner: React.FC = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <Layout.FullWidth
      className={styles.banner}
      data-algolia-remove='true'
      withSeparator
    >
      <div className={styles.container}>
        <div className={styles.hero}>
          <h1 className={styles.title}>Welcome to the Docs</h1>
          <h4 className={styles.subtitle}>
            Here you'll find guides, resources, and references to build with
            Plaid.
          </h4>
          <Layout.Spacer units={10} />
        </div>
        <div className={styles.videoContainer}>
          <Lazy isLazy={false} scrollContainer='.docs-scroll-container'>
            <video
              className={cx(styles.video, {
                [styles.hide]: !isVideoLoaded,
              })}
              autoPlay
              loop
              muted
              width='780'
              height='380'
              playsInline
              onCanPlayCapture={() => {
                setIsVideoLoaded(true);
              }}
            >
              <source
                src='/assets/video/products-dreambig-hero.mp4'
                type='video/mp4'
              />
              <source
                src='/assets/video/products-dreambig-hero.webm'
                type='video/webm'
              />
            </video>
          </Lazy>
          <Image
            className={cx(styles.heroPoster, { [styles.hide]: isVideoLoaded })}
            src='/assets/img/hero/products-dreambig-hero.png'
            alt=''
            width='720'
            height='380'
          />
        </div>
      </div>
    </Layout.FullWidth>
  );
};

Banner.displayName = 'Banner';

export default Banner;
