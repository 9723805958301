import React from 'react';

import Image from '../Image';
import styles from './Learn.module.scss';

interface Props {
  children: React.ReactNode;
}

const Learn: React.FC<Props> = (props: Props) => (
  <div className={styles.learnWrapper}>
    <h6>Learn</h6>
    <div className={styles.learn}>
      <div className={styles.actionCard}>{props.children}</div>
      <Image
        className={styles.image}
        src='/assets/img/docs/auth/auth-quickstart-overview.png'
        alt='Quickstart preview'
      />
    </div>
  </div>
);

Learn.displayName = 'Learn';

export default Learn;
