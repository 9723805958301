import ActionsWrapper from './ActionsWrapper';
import Banner from './Banner';
import ContentWrapper from './ContentWrapper';
import Learn from './Learn';
import ProductsWrapper from './ProductsWrapper';

export default {
  ActionsWrapper,
  Banner,
  ContentWrapper,
  Learn,
  ProductsWrapper,
};
