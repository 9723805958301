import React from 'react';

import Layout from '../Layout';

import styles from './ContentWrapper.module.scss';

interface Props {
  children?: React.ReactNode;
}

const ContentWrapper: React.FC<Props> = (props: Props) => (
  <Layout.FullWidth className={styles.contentWrapper}>
    {props.children}
  </Layout.FullWidth>
);

ContentWrapper.displayName = 'ContentWrapper';

export default ContentWrapper;
