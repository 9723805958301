
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import Mobile from 'plaid-threads/Icons/Mobile';
import Tag from 'plaid-threads/Icons/Tag';
import Terminal from 'plaid-threads/Icons/Terminal';
import Transfer from 'plaid-threads/Icons/Transfer';
import Book from 'plaid-threads/Icons/Book';
import Libraries from 'plaid-threads/Icons/Libraries';
import Rocket from 'plaid-threads/Icons/Rocket';
import Link from 'plaid-threads/Icons/Link';
import ProductAuth from 'plaid-threads/Icons/ProductAuth';
import ProductTransactions from 'plaid-threads/Icons/ProductTransactions';
import Actions from '../../components/docs/Actions';
import AnchorLink from '../../components/docs/AnchorLink';
import Home from '../../components/docs/Home';
export const metadata = {
  feedback: true,
  toc: false,
  description: "Plaid developer documentation home. Here you'll find guides, resources, and references you can use to build with Plaid",
  preloadImages: [{
    src: '/assets/img/hero/products-dreambig-hero.png',
    type: 'image/webp'
  }],
  banner: 'This is a message for testing our new docs banner'
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Home.Banner />
    <Home.ContentWrapper>
      <Home.ActionsWrapper>
    <Actions.Card title='Quickstart' subtitle="Learn about Plaid's key concepts and run starter code" link='/docs/quickstart' linkText='Get started' icon={<Rocket mdxType="Rocket" />} />
    <Actions.Card title='API Reference' subtitle='Explore server-side API libraries and integrate with API endpoints' link='/docs/api' linkText='View reference' icon={<Libraries mdxType="Libraries" />} />
    <Actions.Card title='Link' subtitle="Link, Plaid's client-side component, helps your users connect their accounts" link='/docs/link' linkText='Build with Link' icon={<Mobile mdxType="Mobile" />} />
      </Home.ActionsWrapper>
      <Home.ProductsWrapper category='Payments and Funding' link='/docs/payments/'>
  <Actions.ProductCard link='/docs/auth' title='Auth' subtitle='Retrieve bank account information for ACH, wire, and other bank-to-bank transfers.' src='/assets/img/navbar/icon-auth.png' />
  <Actions.ProductCard link='/docs/balance' title='Balance' subtitle='Retrieve real-time balance information to prevent ACH returns.' src='/assets/img/navbar/icon-balance.png' />
  <Actions.ProductCard link='/docs/identity' title='Identity' subtitle="Verify users' financial account ownership and reduce fraud." src='/assets/img/navbar/icon-identity.png' />
  <Actions.ProductCard link='/docs/signal' title='Signal' subtitle='Reduce fraud and provide instant funds access by assessing the return risk of an ACH debit transaction.' src='/assets/img/navbar/icon-signal.png' />
  <Actions.ProductCard link='/docs/transfer' title='Transfer' subtitle='Send and receive money end to end with Plaid.' src='/assets/img/navbar/icon-transfer.png' />
  <Actions.ProductCard link='/docs/investments-move' title='Investments Move' subtitle="Get verified data for initiating an ACATS or ATON brokerage-to-brokerage transfer." src='/assets/img/navbar/icon-investments-move.png' />
  <Actions.ProductCard link='/docs/payment-initiation' title='Payment Initiation (Europe)' subtitle='Initiate payments within your app.' src='/assets/img/navbar/icon-payment-initiation.png' />
  <Actions.ProductCard link='/docs/virtual-accounts' title='Virtual Accounts (Europe)' subtitle='Use with Payment Initiation to manage the entire lifecycle of a payment, and enable cashouts and refunds.' src='/assets/img/navbar/icon-payouts.png' />
      </Home.ProductsWrapper>
      <Home.ProductsWrapper category='Financial Insights' link='/docs/financial-insights/'>
  <Actions.ProductCard link='/docs/transactions' title='Transactions' subtitle='Retrieve transaction data for budgeting tools, expense management, and more.' src='/assets/img/navbar/icon-transactions.png' />
  <Actions.ProductCard link='/docs/investments' title='Investments' subtitle='View holdings and transactions from investment accounts.' src='/assets/img/navbar/icon-investments.png' />
  <Actions.ProductCard link='/docs/liabilities' title='Liabilities' subtitle='Access loan data, like balances and interest rates, for student loans, mortgages, and credit cards.' src='/assets/img/navbar/icon-liabilities.png' />
  <Actions.ProductCard link='/docs/enrich' title='Enrich' subtitle='Add detailed information and insights to your existing transactions data.' src='/assets/img/navbar/icon-enrich.png' />
      </Home.ProductsWrapper>
      <Home.ProductsWrapper category='KYC and AML Compliance' link='/docs/kyc-aml/'>
  <Actions.ProductCard link='/docs/identity-verification' title='Identity Verification' subtitle='Global KYC compliance and anti-fraud. Verify user identity data and documents.' src='/assets/img/navbar/products-idverification-48@2x.png' />
  <Actions.ProductCard link='/docs/monitor' title='Monitor' subtitle='Use with Identity Verification to enable sanction, PEP, and watchlist screening for anti-money laundering compliance.' src='/assets/img/navbar/products-monitor-48@2x.png' />
      </Home.ProductsWrapper>
      <Home.ProductsWrapper category='Instant Onboarding' link='/docs/layer'>
  <Actions.ProductCard link='/docs/layer' title='Layer' subtitle='Onboard users instantly with just a phone number.' src='/assets/img/navbar/icon-layer.png' />
      </Home.ProductsWrapper>
      <Home.ProductsWrapper category='Credit and Underwriting' link='/docs/underwriting'>
  <Actions.ProductCard link='/docs/assets' title='Assets' subtitle="Access users' financial information for loan underwriting." src='/assets/img/navbar/icon-assets.png' />
  <Actions.ProductCard link='/docs/statements' title='Statements' subtitle="Get PDF statements directly from the user's bank, for underwriting verification and compliance." src='/assets/img/navbar/icon-statements.png' />
  <Actions.ProductCard link='/docs/income' title='Income' subtitle='Verify income and employment, for lending use cases and more.' src='/assets/img/navbar/icon-income.png' />
      </Home.ProductsWrapper>
    </Home.ContentWrapper>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
