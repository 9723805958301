import React from 'react';

import styles from './ActionsWrapper.module.scss';

interface Props {
  children: Array<React.ReactNode>;
}

const ActionsWrapper: React.FC<Props> = (props: Props) => (
  <div className={styles.wrapper}>{props.children}</div>
);

ActionsWrapper.displayName = 'ActionsWrapper';

export default ActionsWrapper;
